import db from "@/components/Firebase/firebaseInit";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import StartupModel from "../models/startupModel";
const programsRef = collection(db, "programs");
function generateId() {
  let id = Math.random().toString(36).substring(2, 8);
  return id;
}
export default {
  namespaced: true,
  state: {
    programs: null,
    defaultSectors: [
      "Blockchain and Cryptocurencies",
      "Education",
      "Enviromental and Energy",
      "Healthcare",
      "Insurance",
      "IT",
      "Marketing and advertising",
      "Real Estate",
      "Retail and Ecommerce",
      "Software (SaaS) and technology",
      "Water and Sanitation",
    ],
    defaultStages: [
      "IPO",
      "Pre-Seed",
      "Seed",
      "Self-funding",
      "Series A",
      "Series B",
      "Series C",
      "Venture",
    ],
  },
  mutations: {},
  actions: {
    // add and update
    // Create
    async createProgram({ dispatch }, program) {
      try {
        if (
          program.additionalQuestions != null &&
          program.additionalQuestions.length > 0
        ) {
          program.additionalQuestions = program.additionalQuestions.map(
            (object) => {
              if (object.id != undefined) {
                return object;
              }
              let question = object;
              let id = generateId();
              let map = { question: question, id: id };
              return map;
            }
          );
          program.additionalQuestions.sort(function (a, b) {
            if (a.question == undefined || b.question == undefined) {
              return 0;
            }
            if (a.question < b.question) {
              return -1;
            }
            if (a.question > b.question) {
              return 1;
            }
            return 0;
          });
          // return;
        }
        await setDoc(doc(db, "programs", program.id), { ...program });
        await dispatch(
          "user/addUserProgram",
          { programId: program.id },
          { root: true }
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // Read
    async getProgram({ state }, programId) {
      try {
        const programRef = doc(db, "programs", programId);
        const program = await getDoc(programRef);
        if (program.exists) {
          var data = program.data();
          if ((await data.sectors) == null) {
            data.sectors = state.defaultSectors;
          }
          if ((await data.stages) == null) {
            data.stages = state.defaultStages;
          }
          const startups = collection(db, "programs", `${programId}/startups`);
          const startupCount = await getCountFromServer(startups);
          data.supported = startupCount.data().count;
          return data;
        } else {
          throw { message: "Program not found" };
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // Update
    async updateProgramInfo({}, _startup = new StartupModel()) {
      return await db
        .collection("programs")
        .doc(_startup.programID)
        .get()
        .then((programs) => {
          programs.forEach((program) => {
            program.ref.update({
              startups: Number(program.data().startups) + 1,
              jobs: Number(program.data().jobs) + Number(_startup.jobs),
              profits:
                Number(program.data().profits) + Number(_startup.profits),
              assets: Number(program.data().assets) + Number(_startup.assets),
              initialCap:
                Number(program.data().initialCap) + Number(_startup.initialCap),
              valuation:
                Number(program.data().valuation) + Number(_startup.valuation),
            });
          });
        });
    },
    async addIncubationManager({}, payload) {
      try {
        const programRef = doc(db, "programs", payload.programId);
        const programData = (await getDoc(programRef)).data();
        if (programData.incubationManagers != null) {
          return await updateDoc(programRef, {
            incubationManagers: arrayUnion(payload.userId),
          });
        } else {
          return await setDoc(programRef, {
            ...programData,
            incubationManagers: [payload.userId],
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async editProgram({}, payload) {
      try {
        const programRef = doc(db, `programs`, payload.programId);
        let program = payload.program;
        if (
          program.additionalQuestions != null &&
          program.additionalQuestions.length > 0
        ) {
          program.additionalQuestions = program.additionalQuestions.map(
            (object, index) => {
              if (object.id != undefined) {
                return object;
              }
              let question = object;
              let id = generateId();
              let map = { question: question, id: id };
              return map;
            }
          );
          // return;
        }
        const updateData = {
          name: program.name,
          start: program.start,
          end: program.end,
          description: program.description,
          additionalQuestions: program.additionalQuestions,
          performanceDataPoints: program.performanceDataPoints
        };
        return await updateDoc(programRef, updateData);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // Delete
    async deleteProgram({}, programId) {
      try {
        await deleteDoc(doc(db, "programs", programId));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addProgramMilestone({}, payload) {
      try {
        const milestoneRef = collection(
          db,
          `milestones/${payload.programId}/milestones`
        );
        return await addDoc(milestoneRef, payload.milestone);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editProgramMilestone({}, payload) {
      try {
        const milestoneRef = doc(
          db,
          `milestones/${payload.programId}/milestones`,
          payload.milestoneId
        );
        return await updateDoc(milestoneRef, payload.milestone);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteProgramMilestone({}, payload) {
      try {
        const milestoneRef = doc(
          db,
          `milestones/${payload.programId}/milestones`,
          payload.milestoneId
        );
        return await deleteDoc(milestoneRef);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  getters: {
    defaultSectors: (state) => state.defaultSectors,
    defaultStages: (state) => state.defaultStages,
    getAllPrograms: () => async (programIds) => {
      console.log(programIds);
      async function getProgramListDetails(programs) {
        let programList = [];
        for await (const program of programs) {
          const startups = collection(db, "programs", `${program.id}/startups`);
          const startupCount = await getCountFromServer(startups);
          const programData = program.data();
          programData.supported = startupCount.data().count;
          programList.push(programData);
        }
        return programList;
      }
      try {
        var allPrograms = await getDocs(programsRef);
        if (programIds != null) {
          if (programIds.length == 0) {
            return [];
          }
          var programs = allPrograms.docs.filter((program) =>
            programIds.includes(program.id)
          );
          return await getProgramListDetails(programs);
        } else {
          return await getProgramListDetails(allPrograms.docs);
        }
      } catch (error) {
        console.error(error);
      }
    },
    getProgramMilestones: () => async (programId) => {
      try {
        const milestones = collection(db, `milestones/${programId}/milestones`);
        const querySnapshot = await getDocs(milestones);
        if (querySnapshot.size > 0) {
          return querySnapshot.docs.map((doc) => {
            const milestone = {
              id: doc.id,
              ...doc.data(),
            };
            return milestone;
          });
        } else {
          return [];
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getAssociatedProgramsByEmail: () => async (email) => {
      let programs = [];
      return new Promise((resolve, reject) => {
        const collRef = db.collection("programs");
        collRef
          .doc(programId)
          .where("email", "==", email)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const program = {
                ...doc.data(),
                id: doc.id,
                // TODO: have a computed startups count in a program
              };
              programs.push(program);
            });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            resolve(programs);
          });
      });
    },
  },
};
