import db from "@/components/Firebase/firebaseInit";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import {
    addDoc,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
const auth = getAuth();
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // Create
    async addUser({}, payload) {
      try {
        if (payload.userId) {
          await setDoc(doc(db, "userbase", payload.userId), { ...payload });
        } else {
          const user = await addDoc(collection(db, "userbase"), { ...payload });
          return user.id;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteUser({}, userID) {
      try {
        const userRef = doc(db, "userbase", userID);
        const user = await getDoc(userRef);
        if (user.exists()) {
          await deleteDoc(userRef);
          // Todo: Remove user auth from firebase auth.
          // await dispatch("auth/deleteUserAuth", user.data(), {
          //   root: true,
          // });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async allUsers() {
      try {
        const querySnapshot = await getDocs(collection(db, "userbase"));
        if (querySnapshot.size > 0) {
          return querySnapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          });
        } else {
          return [];
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async userIsRegistered({}, userEmail) {
      const q = query(
        collection(db, "userbase"),
        where("email", "==", userEmail)
      );
      const user = await getDocs(q);
      const userExists = !user.empty;
      return userExists;
    },
    async userExists({}, userEmail) {
      try {
        const userDetail = await fetchSignInMethodsForEmail(auth, userEmail);

        if (userDetail != null && userDetail.includes("password")) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getUsersById({}, userIds) {
      const usersRef = collection(db, "userbase");
      const usersDocSnap = await getDocs(usersRef);
      let users = [];
      await userIds.forEach((userId) => {
        const userForId = usersDocSnap.docs.filter(
          (doc) => doc.id === userId
        )[0];
        if (userForId) {
          users.push({ ...userForId.data(), userId: userForId.id });
        }
      });
      return users;
    },
    async updateUserData({}, userData = { id: null }) {
      const userRef = doc(db, "userbase", userData.id);
      //
      // console.table(userData);
      return await setDoc(userRef, {
        ...userData,
      });
    },
    async addUserProgram({ dispatch, rootGetters }, { programId, email }) {
      try {
        let user = rootGetters["auth/authUser"];
        let userEmail = email;
        if (email == null) {
          if (user == null) {
            throw { message: "Current user not found" };
          }
          userEmail = user.email;
        }

        if (userEmail) {
          const userData = await dispatch("getUser", userEmail);
          if (userData != null) {
            const userRef = doc(db, "userbase", userData.id);
            if (userData.programs != null) {
              await updateDoc(userRef, {
                programs: arrayUnion(programId),
              });
            } else {
              return await setDoc(userRef, {
                ...userData,
                programs: [programId],
              });
            }
          }
        } else {
          throw { message: "Current user email not found" };
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getLoggedInUserData({ rootGetters, dispatch }) {
      try {

        let user = await rootGetters["auth/authUser"];
        if (user == null || user.email == null) {
          throw { message: "Current User not found" };
        } else {
          const userData = await dispatch("getUser", user.email);

          return userData;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getUser({}, userEmail) {
      try {
        const usersRef = collection(db, "userbase");
        const docSnap = await getDocs(
          query(usersRef, where("email", "==", userEmail))
        );
        if (docSnap.empty) {
          throw { message: "User not registered" };
        } else {
          const user = docSnap.docs[0].data();
          user.id = docSnap.docs[0].id;
          return user;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  getters: {},
};
