var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FadeTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"modal",staticClass:"modal",class:[
      { 'show d-block': _vm.show },
      { 'd-none': !_vm.show },
      { 'modal-mini': _vm.type === 'mini' }
    ],attrs:{"tabindex":"-1","role":"dialog"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"mx-auto",class:[
        { 'modal-notice': _vm.type === 'notice' },
        { 'modal-dialog-centered': _vm.centered },
        _vm.modalClasses
      ],attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",class:[
          _vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
          _vm.modalContentClasses
        ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header modal-header align-items-start p-4",class:[_vm.headerClasses]},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"btn btn-outline-danger rounded-pill position-static m-0",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})]):_vm._e()]})],2):_vm._e(),(_vm.isPrintModal)?_c('div',{staticClass:"d-flex col justify-content-md-end"},[_c('button',{staticClass:"btn btn-default d-print-none mb-4",on:{"click":_vm.printModal}},[_vm._v("Print PDF")])]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"modal-body print",class:_vm.bodyClasses,attrs:{"id":"printableModal"}},[_c('div',{staticClass:"report-bg d-block"}),_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }