<template>
  <FadeTransition :duration="animationDuration">
    <div
      ref="modal"
      class="modal"
      @click.self="closeModal"
      :class="[
        { 'show d-block': show },
        { 'd-none': !show },
        { 'modal-mini': type === 'mini' }
      ]"
      v-show="show"
      tabindex="-1"
      role="dialog"
    >


      <div
        class="mx-auto"
        role="document"
        :class="[
          { 'modal-notice': type === 'notice' },
          { 'modal-dialog-centered': centered },
          modalClasses
        ]"
      >
        <div
          class="modal-content"
          :class="[
            gradient ? `bg-gradient-${gradient}` : '',
            modalContentClasses
          ]"
        >
          <div
            class="modal-header modal-header align-items-start p-4"
            :class="[headerClasses]"
            v-if="$slots.header"
          >
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                type="button"
                class="btn btn-outline-danger rounded-pill position-static m-0"
                v-if="showClose"
                @click="closeModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="tim-icons icon-simple-remove"></i>
              </button>
            </slot>

          </div>
          <div class="d-flex col justify-content-md-end" v-if="isPrintModal">

            <button @click="printModal" class="btn btn-default d-print-none mb-4">Print PDF</button>
          </div>
          <div v-if="$slots.default" class="modal-body print" id="printableModal" :class="bodyClasses">
            <div class="report-bg d-block"></div>
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>

    </div>
  </FadeTransition>

</template>
<script>
import {FadeTransition} from 'vue2-transitions/src';
import html2pdf from "html2pdf.js/src";

export default {
  name: 'modal',
  components: {
    FadeTransition
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    isPrintModal: {
      type: Boolean,
      default: false
    },
    printTitle: {
      type: String,
      default: 'ice180'
    },
    centered: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: 'Whether modal should be appended to document body'
    },
    scrollToBottom: {
      type: Boolean,
      default: false,
      description: "Whether modal should scroll to it's bottom automatically"
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        let acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes'
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)'
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    async printModal() {
      var printElement = document.getElementById('printableModal');
      if(!printElement) return;
      var opt = {
        margin: 0,
        filename: `${this.printTitle} Performance Report.pdf`,
        // image: {type: 'jpeg', quality: 1},
        html2canvas: {scale: 3, logging: false,  allowTaint: true, imageTimeout:0},
        jsPDF: {orientation: 'landscape', format:'a4'}
      };
      // console.table(a.querySelector('table').style.width);
      await html2pdf().from(printElement).set(opt).output('dataurlnewwindow').save();
      this.closeModal();

    },
    scrollModalToBottom() {
      if (!this.scrollToBottom) return;
      let elm = this.$refs.modal;
      elm.scrollTop = elm.scrollHeight - elm.clientHeight;
    }
  },
  mounted() {

    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  destroyed() {
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;
      if (val) {
        if (!this.isPrintModal) {
          documentClasses.add(['modal-open']);
          this.$nextTick(this.scrollModalToBottom);
        }else{
          this.printModal();
          // setTimeout(this.printModal, 1000);
        }

      } else {
        documentClasses.remove('modal-open');
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.z-index-under {
  z-index: -1;
}
.report-bg{
  height: 400px;
  width: 400px;
  position: absolute;
  background-image: linear-gradient(-135deg, red, orange 80%);
  bottom: -225px;
  left: -225px;
  border-radius: 100%;
  //filter: blur(20px);
}

.print{
  height: 210mm;
  overflow: hidden;
}

/*
  .modal.show {
    background-color: white;
    width: calc(3 * 100vw) !important;
  }
  .modal-dialog{
    width: 100% !important;
  }
  body.modal-open{
width: fit-content;
  }
  .modal-body {
    overflow: visible !important;
  }
  div.el-table{
    overflow: visible;
  }
  .el-table--scrollable-x .el-table__body-wrapper{
    overflow: visible !important;
    background-color:blue !important;
  }
  .el-table__footer-wrapper, .el-table__header-wrapper{
    overflow: visible !important;
    background-color: yellow !important;
  }
    .wrapper{
    display: none;
  }
 */

</style>
